import React from "react";

class NotFound extends React.Component {
    render() {
        return (
            <div>
                <p style={{margin: "0 auto", fontSize: "xxx-large", textAlign: "center"}}>Erreur 404 : j'ai bien cherché mais j'ai rien trouvé...</p>
            </div>
        );
    }
}

export default NotFound;