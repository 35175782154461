import React from "react";

class Lorient extends React.Component {
    render() {
        return (
            <div>
                <p>Lorient</p>
            </div>
        )
    }
}

export default Lorient